import { styled } from '@mui/material'

export const Root = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export const Text = styled('h3')`
  color: ${props => props.theme.palette.gray[20]};
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;
  margin: 0 0 0.5rem;
`

export const Image404 = styled('img')`
  width: 50%;
`
