import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from '@chilecompra/react-kit/utils/effects'
import Spinner from '@chilecompra/react-kit/components/Basics/Spinner'
import { onLogoutThunk } from './Logout.actions'
import { Root } from './Logout.styles'

/**
 * The Logout's container.
 */
const Logout = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(onLogoutThunk())
  })

  return (
    <Root>
      <Spinner />
    </Root>
  )
}

export default Logout
