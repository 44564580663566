import React from 'react'
import { ProtectBySession } from '@chilecompra/mnt-layout'
import LoginContainer from '../containers/Login/Login.container'

/**
 * The Login page.
 */
const Login = () => (
  <ProtectBySession rule={hasSession => !hasSession}>
    <LoginContainer />
  </ProtectBySession>
)

export default Login
