import React from 'react'
import { Image404, Root, Text } from './NotFound.styles'

/**
 * The NotFound' page.
 */
const NotFound = () => (
  <Root>
    <Text>La página solicitada no existe</Text>
    <Image404 alt="La página solicitada no existe" src="/assets/images/404.svg" />
  </Root>
)

export default NotFound
