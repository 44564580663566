import {
  CLEAN_ERROR_SUCCESS,
  LOGGING_IN,
  LOGGING_IN_ERROR,
  LOGGING_IN_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS
} from './Login.actions'

const loginState = {
  error: '',
  loading: false,
  loggingIn: true
}

/**
 * The login reducer.
 */
const loginReducer = (state = loginState, { payload, type }) => {
  switch (type) {
    case CLEAN_ERROR_SUCCESS: {
      return { ...state, error: '' }
    }
    case LOGGING_IN: {
      return { ...state, loggingIn: true }
    }
    case LOGIN: {
      return { ...state, loading: true }
    }
    case LOGGING_IN_ERROR:
    case LOGIN_ERROR: {
      return { ...state, error: payload.reason, loading: false, loggingIn: false }
    }
    case LOGGING_IN_SUCCESS:
    case LOGIN_SUCCESS: {
      return loginState
    }
    default: {
      return state
    }
  }
}

export default loginReducer
