import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from '@chilecompra/react-kit/utils/effects'
import Spinner from '@chilecompra/react-kit/components/Basics/Spinner'
import Typography from '@chilecompra/react-kit/components/Basics/Typography'
import { onCleanErrorSuccess, onLoggingInThunk, onLoginThunk } from './Login.actions'
import { ErrorText, Grid, Logo, MicrosoftButton, MicrosoftIcon, Root, Section, Wrapper } from './Login.styles.jsx'

/**
 * The Login's container.
 */
const Login = () => {
  const dispatch = useDispatch()
  const { error, loading, loggingIn } = useSelector(state => state.login)
  const handleLogin = () => dispatch(onLoginThunk())
  const handleBack = () => dispatch(onCleanErrorSuccess())

  useEffectOnce(() => {
    dispatch(onLoggingInThunk())
  })

  return (
    <Root
      alignItems={loggingIn ? 'center' : 'initial'}
      height={loggingIn ? 'calc(100vh - 236px)' : '100%'}
      justifyContent={loggingIn ? 'center' : 'initial'}
    >
      {loggingIn && <Spinner />}
      {!loggingIn && (
        <Grid container direction="column" margin="auto">
          <Wrapper color="footer" padding="55px 48px">
            <Section align="left" display="flex" margin="0 auto">
              <Logo alt="Chilecompra" src="/assets/images/logo_mercadopublico-white.svg" />
              <Typography color="white" fontWeight="light" variant="body2">
                PANEL DE ADMINISTRACIÓN
              </Typography>
            </Section>
            <Section align="center" display="flex" margin="30px auto">
              <Typography color="white" fontWeight="light" variant="h3">
                Ingreso al panel de administración
                <br />
                de Mercado Público
              </Typography>
            </Section>
          </Wrapper>
          <Wrapper color="gray" padding="33px 48px 50px">
            <Section align="center" display="block">
              <ErrorText>
                {error && error !== 'UNAUTHENTICATED_ERROR'
                  ? 'Esta cuenta de usuario no tiene permisos para ingresar al panel de administración.'
                  : ''}
              </ErrorText>
              {error && error !== 'UNAUTHENTICATED_ERROR' ? (
                <MicrosoftButton onClick={handleBack}>Volver</MicrosoftButton>
              ) : (
                <MicrosoftButton disabled={loading} onClick={handleLogin}>
                  <MicrosoftIcon alt="Iniciar sesión" src="/assets/images/ms_symbol.svg" />
                  Iniciar sesión
                </MicrosoftButton>
              )}
            </Section>
            <Section align="center" display="flex">
              <Typography align="center" variant="body2">
                Si tienes problemas para iniciar sesión contáctate con el
                <br />
                Departamento de Operaciones
              </Typography>
            </Section>
          </Wrapper>
        </Grid>
      )}
    </Root>
  )
}

export default Login
