import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { initializeLogger, ThemeProvider } from '@chilecompra/react-kit'
import { InitializeLayout } from '@chilecompra/mnt-layout'
import {
  REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
  REACT_APP_AZURE_ACTIVE_DIRECTORY_SCOPE,
  REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID,
  REACT_APP_BASE_URL,
  REACT_APP_DEFAULT_REDIRECT_TO_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_LOGIN_URL,
  REACT_APP_LOGOUT_URL
} from './config/settings/environment'
import getStoreSingleton from './config/store/store'
import Routing from './Routing'

initializeLogger({ env: REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev' })

const container = document.getElementById('__root')
const root = createRoot(container)
const store = getStoreSingleton()

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <InitializeLayout
          enableAzureSdk
          clientId={REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID}
          defaultRedirectTo={REACT_APP_DEFAULT_REDIRECT_TO_URL}
          loginUrl={REACT_APP_LOGIN_URL}
          logoutUrl={REACT_APP_LOGOUT_URL}
          metadataBaseUrl={REACT_APP_BASE_URL}
          scope={REACT_APP_AZURE_ACTIVE_DIRECTORY_SCOPE}
          tenantId={REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID}
        >
          <Routing />
        </InitializeLayout>
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>
)
