import { login, loginCallback } from '@chilecompra/mnt-layout'
import customizeError from '@chilecompra/react-kit/errors/custom'
import { makeActionCreator } from '../../config/store/utils'

export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const onLogin = makeActionCreator(LOGIN)
export const onLoginError = makeActionCreator(LOGIN_ERROR, 'payload')
export const onLoginSuccess = makeActionCreator(LOGIN_SUCCESS)
export const onLoginThunk = () => async dispatch => {
  dispatch(onLogin())

  try {
    await login()

    return dispatch(onLoginSuccess())
  } catch (error) {
    return dispatch(onLoginError(customizeError({ ...error, reason: error?.reason || 'LOGIN_FAILED' }).toObject()))
  }
}

export const LOGGING_IN = 'LOGGING_IN'
export const LOGGING_IN_ERROR = 'LOGGING_IN_ERROR'
export const LOGGING_IN_SUCCESS = 'LOGGING_IN_SUCCESS'
export const onLoggingIn = makeActionCreator(LOGGING_IN)
export const onLoggingInError = makeActionCreator(LOGGING_IN_ERROR, 'payload')
export const onLoggingInSuccess = makeActionCreator(LOGGING_IN_SUCCESS)
export const onLoggingInThunk = () => async dispatch => {
  dispatch(onLoggingIn())

  try {
    await loginCallback()

    return dispatch(onLoggingInSuccess())
  } catch (error) {
    return dispatch(
      onLoggingInError(customizeError({ ...error, reason: error?.reason || 'LOGGING_FAILED' }).toObject())
    )
  }
}

export const CLEAN_ERROR_SUCCESS = 'CLEAN_ERROR_SUCCESS'
export const onCleanErrorSuccess = makeActionCreator(CLEAN_ERROR_SUCCESS)
