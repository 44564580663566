import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routes from './config/settings/routes'
import HomePage from './pages/Home'
import LoginPage from './pages/Login'
import LogoutPage from './pages/Logout'
import NotFoundPage from './pages/NotFound'

/**
 * The Routing's component.
 */
const Routing = () => (
  <BrowserRouter>
    <Routes>
      <Route caseSensitive element={<HomePage />} path={routes.root} />
      <Route caseSensitive element={<LoginPage />} path={routes.login} />
      <Route caseSensitive element={<LogoutPage />} path={routes.logout} />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  </BrowserRouter>
)

export default Routing
