import React from 'react'
import { Grid as BaseGrid, styled } from '@mui/material'

export const ErrorText = styled('p')`
  color: ${props => props.theme.palette.gray[20]};
  font-size: 14px;
  height: 40px;
`

export const Grid = styled(BaseGrid)`
  background-color: ${props => props.theme.palette.gray[95]};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin: ${props => (props.margin ? props.margin : 0)};
  max-width: 460px;

  ${props => props.theme.breakpoints.only('xs')} {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`

export const Logo = styled('img')`
  height: auto;
  pointer-events: none;
  user-select: none;
  width: 215px;

  ${props => props.theme.breakpoints.only('xs')} {
    .MuiGrid-root {
      margin: 0 auto;
    }
  }
`

export const MicrosoftButton = styled('button')`
  align-items: center;
  background-color: ${props => props.theme.palette.brand.white};
  border: 1px solid ${props => props.theme.palette.brand.black3};
  color: ${props => props.theme.palette.brand.disableFont};
  cursor: pointer;
  display: flex;
  font-family: Segoe UI;
  font-weight: 600;
  height: 41px;
  justify-content: center;
  margin: 0 auto 57px;
  min-width: 148px;
  padding-right: 12px;

  &:disabled {
    cursor: not-allowed;
  }
`

export const MicrosoftIcon = styled('img')`
  height: auto;
  padding: 0 12px;
  pointer-events: none;
  user-select: none;
  width: 25px;
`

export const Root = styled(({ alignItems, justifyContent, ...props }) => <div {...props} />)`
  align-items: ${props => props.alignItems || 'initial'};
  display: flex;
  height: ${props => props.height || '100%'};
  justify-content: ${props => props.justifyContent || 'initial'};
  width: 100%;

  ${props => props.theme.breakpoints.only('xs')} {
    min-height: 100vh;
  }
`

export const Section = styled(({ display, margin, ...props }) => <section {...props} />)`
  display: ${props => props.display || 'block'};
  flex-direction: ${props => (props.display === 'flex' ? 'column' : 'inherit')};
  justify-content: ${props => {
    if (props.align === 'center') {
      return props.align
    }

    if (props.align === 'right') {
      return 'flex-end'
    }

    return 'flex-start'
  }};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
`

export const Wrapper = styled('div')`
  background-color: ${({ color, theme }) => (color === 'footer' ? theme.palette.blue[15] : 'transparent')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.padding};
`
