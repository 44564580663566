import React from 'react'
import { Layout as BrandLayout, ProtectBySession } from '@chilecompra/mnt-layout'
import Typography from '@chilecompra/react-kit/components/Basics/Typography'
import { Row } from './Home.styles'

/**
 * The Home' page.
 */
const Home = () => (
  <ProtectBySession rule={hasSession => !!hasSession}>
    <BrandLayout>
      <Row>
        <Typography align="center" fontWeight="light" variant="h3">
          Selecciona una opción en el menú lateral para comenzar.
        </Typography>
      </Row>
    </BrandLayout>
  </ProtectBySession>
)

export default Home
