import React from 'react'
import { ProtectBySession } from '@chilecompra/mnt-layout'
import LogoutContainer from '../containers/Logout/Logout.container'

/**
 * The Logout' page.
 */
const Logout = () => (
  <ProtectBySession disablePreserveOrigin rule={hasSession => !!hasSession}>
    <LogoutContainer />
  </ProtectBySession>
)

export default Logout
