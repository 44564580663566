export const {
  REACT_APP_API_BASE_URL,
  REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
  REACT_APP_AZURE_ACTIVE_DIRECTORY_SCOPE,
  REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID,
  REACT_APP_BASE_URL,
  REACT_APP_DEFAULT_REDIRECT_TO_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_LOGIN_URL,
  REACT_APP_LOGOUT_URL
} = process.env
export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod' && process.env.REACT_APP_ENVIRONMENT !== 'test'
export const isLocalEnv = process.env.REACT_APP_ENVIRONMENT === 'local'
