import { logout } from '@chilecompra/mnt-layout'
import { makeActionCreator } from '../../config/store/utils'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const onLogout = makeActionCreator(LOGOUT)
export const onLogoutError = makeActionCreator(LOGOUT_ERROR)
export const onLogoutSuccess = makeActionCreator(LOGOUT_SUCCESS)
export const onLogoutThunk = () => async dispatch => {
  dispatch(onLogout())

  try {
    await logout()

    return dispatch(onLogoutSuccess())
  } catch (error) {
    return dispatch(onLogoutError(error.toObject()))
  }
}
